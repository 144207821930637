<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput
          id="alert-title"
          description="Título de la alerta"
          label="Título de la alerta"
          horizontal
        />
        <CTextarea
          id="alert-description"
          description="Descripción"
          label="Descripción"
          horizontal
          enabled
        />

        <CRow class="form-input-row">
          <CCol lg="3" md="3">Tipo de alerta</CCol>
          <CCol lg="9" md="9" class="select-row">
            <select id="alert-type">
              <option>Elige tipo de alerta</option>
              <option
                v-for="(alert, index) in alertTypes"
                :key="index"
                :value="alert.alert_type_id"
                >{{ alert.alert_type_name }}</option>
            </select>
          </CCol>
        </CRow>
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          style="margin-right: 1em"
          @click="back()"
        >{{backButton}}</CButton>
        <CButton class="btn btn-success" @click="editButton()">{{ textButton }}</CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol md="9" class="p-4">
          <CAlert color="danger" dismissible :show.sync="contAlertParams" close-button>
            <strong>AVISO</strong>
            {{errorText}}
            <CProgress
              :max="10"
              :value="contAlertParams"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {getAlertTypeData, checkUser} from '../../utils/utils';
function getDataReadyToCreate() {
  let alertTitle = document.getElementById("alert-title").value;
  let alertDescription = document.getElementById("alert-description").value;
  let alertType = document.getElementById("alert-type").value;
  let new_alert = {
    alert_title: alertTitle,
    alert_description: alertDescription,
    alert_type: alertType
  };
  return new_alert;
}
export default {
  name: "EditAlert",
  data() {
    return {
      id: null,
      alertTypes: [],
      title: "Nueva Alerta",
      textButton: "Crear",
      backButton: "Volver",
      contAlertParams: 0,
      errorText: 'Campos vacios o erroneos'
    };
  },
  created() {
    if(!checkUser()) return;
    this.getAlertsTypes();
  },
  methods: {
    getAlertsTypes() {
      let data = getAlertTypeData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.alertTypes = result.alert_types;
      });
      return this.alertTypes;
    },
    /**
     * Funcion encargada de crear la alerta
     */
    editButton() {
      let alertTitle = document.getElementById("alert-title").value;
      let alertDescription = document.getElementById("alert-description").value;
      let alertType = document.getElementById("alert-type").value;
      if (alertTitle == ""){
        this.errorText="El campo Título de la alerta esta vacio";
        this.showFailureParameters();
        return;
      }
      if (alertDescription==""){
        this.errorText="El campo Descripción esta vacio";
        this.showFailureParameters();
        return;
      }
      if (alertType=="Elige tipo de alerta"){
        this.errorText="Seleccione un tipo de alerta";
        this.showFailureParameters();
        return;
      }
      if (alertTitle.length>100){
        this.errorText = "El nombre es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      if (alertDescription.length>400){
        this.errorText = "La descripción es demasiado larga.";
        this.showFailureParameters();
        correct = false;
      }


      let token = localStorage.token;
      
      if (!this.id) {
        axios
          .post(config.BACK_IP + config.BACK_IP_ALERTS, getDataReadyToCreate(), {
            headers: {
              token: token
            }
          })
          .then(() => {
            this.$router.push("/alert");
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status == 400) {
                this.errorText=error.response.data.message;
                this.showFailureParameters();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/pages/404";
              }
              if (error.response.status == 409) {
                this.errorText = 'Conflicto con duplicidad';
                this.showFailureParameters();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });
        return;
      }
    },
    back() {
      window.history.back();
    },
    showFailureParameters() {
      this.contAlertParams = 10;
    }
  }
};
</script>